import React from 'react'
import {Link} from 'gatsby'

import styles from './RsvpAddressing.module.css'

const replaceAddressing = (text = '', addressing, addressingType) => {
  if (addressingType === null) {
    return
  }
  const mutations = addressingType.split(',') || []
  let finalText
  finalText = text.replace(new RegExp('##kdo##', 'g'), addressing)
  finalText = finalText.replace(new RegExp('##te##', 'g'), mutations[0])

  return finalText
}

const RsvpAddressing = ({
  addressingText,
  addressing,
  addressingType
}) => (
  <div className={styles.addressing}>
    {replaceAddressing(addressingText, addressing, addressingType)}
    <div className={styles.homePageLink}>
      <Link to='/'>
        <p>{'Více informací naleznete zde'}</p>
      </Link>
    </div>
  </div>
)

export default RsvpAddressing
