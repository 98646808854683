import React, {useState, useEffect} from 'react'
import sanityClient from '@sanity/client'
import {graphql} from 'gatsby'
import fetch from 'node-fetch'
import uuid from '@sanity/uuid'
import Helmet from 'react-helmet'

import Container from '../components/container'
import Button from '../components/Button'
import GraphQLErrorList from '../components/graphql-error-list'
import InvitedGuests from '../components/invited-guests-form'
import GuestForm from '../components/guest-form'
import RsvpAddressing from '../components/Layout/RsvpAddressing'

import RsvpLayout from '../containers/RsvpLayout'

import styles from './invitation.module.css'

const client = sanityClient({
  projectId: '652rabt6',
  dataset: 'production',
  token: process.env.SANITY_READ_TOKEN,
  useCdn: false
})

const sanityQuery = '{"invitedGuests":*[_type == "guest" && !(_id in path("drafts.**")) && invitation._ref == $invitationId]{addedGuest, attend, child, name, _id, answers},"addedGuests":*[_type == "invitation" && _id == $invitationId]{addedGuests}, "questions":*[_type == "question" && !(_id in path("drafts.**"))] | order(order asc) {question, _id, items}}'

const initialApiObject = {
  loaded: false,
  requesting: false,
  error: false
}
const requestingApiObject = {
  loaded: false,
  requesting: true,
  error: false
}
const loadedApiObject = {
  loaded: true,
  requesting: false,
  error: false
}
const errorApiObject = {
  loaded: false,
  requesting: false,
  error: true
}
const componentShouldLoad = (api) => (
  api.loaded === false && api.requesting === false && api.error === false
)
const removeDraft = (_ref = '') => {
  if (_ref.includes('drafts.')) {
    return _ref.split('drafts.')[1]
  }

  return _ref
}

const disableCreateGuest = (invitedGuests = [], addedGuests) => {
  const filteredGuests = invitedGuests.filter(guest => guest.addedGuest === true)

  return filteredGuests.length >= addedGuests
}

export const query = graphql`
  query InvitationTemplateQuery($_id: String!) {
    invitation: sanityInvitation(_id: {eq: $_id}) {
      addressing,
      addressingType,
    },
    rsvpSettings: sanityRsvp {
      addressingText,
      formText,
      sendButton,
      addButton,
      namePlaceholder,
    }
  }
`

const InvitationTemplate = props => {
  const {
    data: {
      invitation: {
        addressing,
        addressingType
      } = {},
      rsvpSettings: {
        addressingText,
        formText,
        sendButton,
        addButton,
        namePlaceholder
      } = {}
    } = {},
    pageContext: {
      _id: invitationId
    },
    errors
  } = props
  const [invitationState, setInvitationState] = useState({})
  const [questionsState, setQuestionsState] = useState([])
  const [invitationApiState, setInvitationApiState] = useState(initialApiObject)
  const [submitApiState, setSubmitApiState] = useState(initialApiObject)
  const [addGuestApiState, setAddGuestApiState] = useState(initialApiObject)
  const {
    invitedGuests,
    addedGuests
  } = invitationState

  useEffect(() => {
    if (componentShouldLoad(invitationApiState)) {
      setInvitationApiState(requestingApiObject)
      client.fetch(sanityQuery, {invitationId: removeDraft(invitationId)}).then(data => {
        const {
          invitedGuests = [],
          addedGuests: [
            {
              addedGuests
            } = {}
          ] = [],
          questions
        } = data

        setInvitationState({
          invitedGuests: invitedGuests
            .map(guest => {
              if (
                !guest.answers ||
                guest.answers === null ||
                (Array.isArray(guest.answers) && guest.answers.length === 0)
              ) {
                return ({
                  ...guest,
                  answers: questions
                    .map(question => ({
                      _key: uuid(),
                      _type: 'formAnswer'
                    }))
                })
              }
              return (guest)
            })
            .sort((a, b) =>
              a.addedGuest < b.addedGuest
                ? -1
                : 1
            ),
          addedGuests
        })
        setQuestionsState(questions)
        setInvitationApiState(loadedApiObject)
      })
    }
  })

  const handleSubmit = async () => {
    if (!submitApiState.requesting) {
      setSubmitApiState(requestingApiObject)
      try {
        const response = await fetch('/.netlify/functions/attendGuest', {
          method: 'POST',
          body: JSON.stringify({data: invitedGuests})
        })
        if (!response.ok) {
          setSubmitApiState(errorApiObject)
          return
        }
        setSubmitApiState(loadedApiObject)
      } catch (error) {
        setSubmitApiState(errorApiObject)
      }
    }
  }

  const handleCreateGuest = async () => {
    if (!addGuestApiState.requesting) {
      setAddGuestApiState(requestingApiObject)
      try {
        fetch('/.netlify/functions/createGuest', {
          method: 'POST',
          body: JSON.stringify({data: invitationId})
        })
          .then(response => response.json())
          .then(data => {
            const {
              _id,
              child,
              addedGuest
            } = data
            const createdGuest = {
              _id,
              child,
              addedGuest
            }
            setInvitationState({
              ...invitationState,
              invitedGuests: [...invitedGuests, createdGuest]
            })
            setAddGuestApiState(loadedApiObject)
          })
      } catch (error) {
        setAddGuestApiState(errorApiObject)
      }
    }
  }

  const successClass = addGuestApiState.loaded
    ? styles.success
    : undefined

  return (
    <RsvpLayout>
      <Helmet>
        <link rel='stylesheet' href='https://use.typekit.net/gsg0mqq.css' />
      </Helmet>
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <RsvpAddressing
        addressing={addressing}
        addressingType={addressingType}
        addressingText={addressingText}
      />
      <InvitedGuests
        invitationState={invitationState}
        setInvitationState={setInvitationState}
        namePlaceholder={namePlaceholder}
      />
      <div className={[styles.buttons, successClass].join(' ')}>
        {addedGuests > 0 && <Button
          onClick={handleCreateGuest}
          apiState={addGuestApiState}
          disabled={disableCreateGuest(invitedGuests, addedGuests)}
        >
          {addButton}
        </Button>}
      </div>
      <div className={[styles.form, successClass].join(' ')}>
        <GuestForm
          formText={formText}
          invitationState={invitationState}
          questionsState={questionsState}
          setInvitationState={setInvitationState}
        />
      </div>
      <div className={[styles.buttons, successClass].join(' ')}>
        <Button
          onClick={handleSubmit}
          apiState={submitApiState}
        >
          {sendButton}
        </Button>
        {submitApiState.loaded && 'Odesláno'}
      </div>
    </RsvpLayout>
  )
}

export default InvitationTemplate
