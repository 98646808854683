import React from 'react'
import RsvpHeader from './RsvpHeader'

import '../../styles/layout.css'
import Footer from '../Footer/Footer'
import styles from './Layout.module.css'
import {cn} from '../../lib/helpers'

const RsvpLayout = ({
  children,
  weddingDate,
  leftQuote,
  rightQuote,
  rsvpHeader
}) => (
  <>
    <RsvpHeader
      weddingDate={weddingDate}
      leftQuote={leftQuote}
      rightQuote={rightQuote}
    />
    <div className={cn(styles.content, styles.rsvpContent)}>
      <h1 className={styles.headline}>{rsvpHeader}</h1>
      {children}
    </div>
    {/* <footer className={styles.footer}>
      <Footer />
    </footer> */}
  </>
)

export default RsvpLayout
