import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import RsvpLayout from '../components/Layout/RsvpLayout'

const query = graphql`
  query RsvpLayoutQuery {
    rsvpSettings: sanityRsvp(_id: {regex: "/(drafts.|)/"}) {
      weddingDate,
      leftQuote,
      rightQuote,
      addressingText,
      rsvpHeader,
    }
  }
`

const LayoutContainer = (props) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          rsvpSettings: {
            weddingDate,
            leftQuote,
            rightQuote,
            rsvpHeader
          } = {}
        } = data
        return (
          <RsvpLayout
            {...props}
            weddingDate={weddingDate}
            leftQuote={leftQuote}
            rightQuote={rightQuote}
            rsvpHeader={rsvpHeader}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
