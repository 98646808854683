import React from 'react'

import styles from './invited-guests-form.module.css'

const InvitedGuests = (props) => {
  const {
    invitationState: {
      invitedGuests = [],
      ...restOfState
    } = {},
    setInvitationState = () => {},
    namePlaceholder
  } = props

  const handleGuestChange = (e = {}) => {
    const {
      target: {
        dataset: {
          idx,
          attribute
        } = {},
        checked,
        value
      } = {}
    } = e

    const updatedGuests = [...invitedGuests]
    if (attribute === 'name') {
      updatedGuests[idx][attribute] = value
    } else {
      updatedGuests[idx][attribute] = checked
    }

    setInvitationState({
      ...restOfState,
      invitedGuests: updatedGuests
    })
  }

  return (
    <div>
      <form>
        {
          invitedGuests.map((val, idx) => (
            <div className={styles.row} key={`guest-${idx}`}>
              <div className={styles.labelContainer} >
                {val.addedGuest
                  ? <input
                    className={styles.name}
                    type='text'
                    data-idx={idx}
                    data-attribute={'name'}
                    name={`guest-${idx}-name`}
                    id={`guest-${idx}-name`}
                    value={
                      val.name === undefined
                        ? ''
                        : val.name
                    }
                    placeholder={namePlaceholder}
                    onChange={handleGuestChange}
                  />
                  : <label htmlFor={`guest-${idx}-attend`} >{val.name}</label>
                }
              </div>
              <input
                className={styles.attend}
                type='checkbox'
                data-idx={idx}
                data-attribute={'attend'}
                name={`guest-${idx}-attend`}
                id={`guest-${idx}-attend`}
                checked={val.attend}
                value={val._id}
                onChange={handleGuestChange}
              />
              <label htmlFor={`guest-${idx}-attend`} >
                <span
                  className={`${styles.yes} ${val.attend
                    ? styles.highlight
                    : ''
                  }`}
                >
                  {'ANO'}
                </span>
                <span
                  className={`${styles.no} ${val.attend
                    ? ''
                    : styles.highlight
                  }`}
                >{'NE'}</span>
              </label>
              {val.addedGuest && <>
                <input
                  className={styles.child}
                  type='checkbox'
                  data-idx={idx}
                  data-attribute={'child'}
                  name={`guest-${idx}-child`}
                  id={`guest-${idx}-child`}
                  checked={
                    val.child === undefined
                      ? false
                      : val.child
                  }
                  onChange={handleGuestChange}
                />
                <label className={styles.childLabel} htmlFor={`guest-${idx}-child`} />
              </>
              }
            </div>
          ))
        }
      </form>
    </div>
  )
}

export default InvitedGuests
