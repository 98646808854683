import React from 'react'

import styles from './guest-form.module.css'

const getChecked = (value = {}, guestIndex, answerKey) => {
  const {
    answers = []
  } = value

  const answer = answers[guestIndex] || {}

  return answer._key === answerKey
}

const getValue = (questions = [], questionIndex, answerIndex) => {
  const question = questions[questionIndex] || {}
  const answers = question.items || []
  const answer = answers[answerIndex] || {}

  return {
    question: question.question,
    answer: answer.answer,
    _key: answer._key
  }
}

const getName = (name) => {
  return name
}

const GuestForm = (props) => {
  const {
    formText,
    invitationState: {
      invitedGuests = [],
      ...restOfState
    } = {},
    questionsState = [],
    setInvitationState = () => {}
  } = props
  const attendingGuests = invitedGuests.filter(guest => guest.attend === true)

  const handleGuestChange = (e = {}) => {
    const {
      target: {
        dataset: {
          idx,
          idy,
          questionindex
        } = {}
      } = {}
    } = e
    console.log(idx, idy, questionindex)
    const value = getValue(questionsState, questionindex, idy)

    const updatedGuests = [...invitedGuests]
    if (updatedGuests[idx]['answers'] === undefined) {
      updatedGuests[idx]['answers'] = []
    }

    updatedGuests[idx]['answers'][questionindex] = {
      ...updatedGuests[idx]['answers'][questionindex],
      ...value
    }
    setInvitationState({
      ...restOfState,
      invitedGuests: updatedGuests
    })
  }

  return (
    <div className={styles.formContainer}>
      {attendingGuests.length > 0 &&
        <div className={styles.formTextContainer}>
          <p className={styles.formText}>{formText}</p>
        </div>
      }
      <form>
        {attendingGuests.length > 0 && questionsState.map((question = {}, questionIndex) => {
          let renderedGuest = 0
          return (
            <div key={`question-${questionIndex}`}>
              <div className={styles.headerRow}>
                <h3 className={styles.questionHeader}>{question.question}</h3>
                {
                  invitedGuests.map((guest, index) => {
                    if (guest.attend === true) {
                      return (
                        <span
                          key={`guest-label-${index}`}
                          className={styles.answerInputContainer}
                        >
                          {getName(guest.name)}
                        </span>
                      )
                    }
                  })
                }
              </div>
              <div className={styles.columnContainer}>
                {
                  invitedGuests.map((val, idx) => {
                    if (val.attend === true) {
                      renderedGuest++
                      return (
                        <div className={styles.column} key={`guest-${idx}`}>
                          {question.items.map((answer = {}, idy) => (
                            <div className={styles.answer} key={`answer-${idy}`}>
                              {renderedGuest === 1 && <p className={styles.answerText}>{answer.answer}</p>}
                              <div className={styles.answerInputContainer}>
                                <input
                                  className={styles.answerInput}
                                  type='radio'
                                  name={`question-${questionIndex}-guest-${idx}`}
                                  data-idx={idx}
                                  data-idy={idy}
                                  data-questionindex={questionIndex}
                                  id={`question-${questionIndex}-guest-${idx}-answer-${idy}`}
                                  checked={getChecked(val, questionIndex, answer._key)}
                                  onChange={handleGuestChange}
                                />
                                <label htmlFor={`question-${questionIndex}-guest-${idx}-answer-${idy}`} />
                              </div>

                            </div>
                          ))}
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>
          )
        })}
      </form>
    </div>
  )
}

export default GuestForm
